<template>
  <b-row>
    <b-col sm="12">
      <my-card title="Daftar Pengguna">
        <template slot="button">
          <button-link v-if="cP(21)" feather-icon="UsersIcon" :to="{name:'usergroups'}" name="Jabatan"></button-link>
          <button-add v-if="cP(13)" :to="{name: 'users-add'}" variant="primary"></button-add>
        </template>
        <template slot="body">
          <data-tables ref="datatables" endpoint="v1/users" :fields="fields">
            <template v-slot:4="{item}">
              {{ item[4] | fulldate }}
            </template>
            <template v-slot:5="{item}">
              <div class="float-none">
                <button-link v-if="cP(16)" size="sm" :to="{name:'users-show',params:{id: item[5]}}" feather-icon="FolderIcon"></button-link>
                <button-link v-if="cP(14)" size="sm" :to="{name:'users-edit',params:{id: item[5]}}" feather-icon="EditIcon"></button-link>
                <button-delete v-if="cP(15)" @ondelete="deleteRow" :id="item[5]" :name="item[0]"></button-delete>
              </div>
            </template>
          </data-tables>
        </template>
      </my-card>
    </b-col>
  </b-row>
</template>

<script>
import ButtonAdd from '@/my-components/ButtonAdd.vue'
import DataTables from '@/my-components/DataTables.vue'
import ButtonDelete from '@/my-components/ButtonDelete.vue'
import ButtonLink from '@/my-components/ButtonLink.vue'

export default {
  components:{
    ButtonAdd,
    DataTables,
    ButtonDelete,
    ButtonLink
  },
  data() {
    return {
      fields: {
        0:{sortable: true, searchable: true, label:'Username'},
        1:{sortable: true, searchable: true, label:'Email'},
        2:{sortable: true, searchable: true, label:'Jabatan'},
        3:{sortable: true, searchable: true, label:'Status'},
        4:{sortable: true, searchable: true, label:'Aktivitas Terakhir'},
        5:{sortable: false, searchable: false, label:''},
      }
    }
  },
  methods:{
    async deleteRow(payload){
      try {
        await this.$store.dispatch('users/destroy',payload)
        this.$refs.datatables.refresh()
        this.toastSuccess("OK","Data Berhasil Dihapus!")
      } catch (error) {
        this.handleError(error)
      }
    }
  }
}
</script>

<style>

</style>